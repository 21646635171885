<script setup lang="ts">
import { inject, watch, type Ref } from "vue";
import { GAlert, GEmailTagTextarea } from "@/components";
import { useI18n } from "vue-i18n";
import { BULK_ACTIONS_MODAL_CONTEXT } from "./context";
import type { ContextWithData } from "./types";
import { useEmailValidation } from "@/composables/useEmailValidation";

const { t } = useI18n();
const { blockCurrentUserEmailSchema } = useEmailValidation();

const { setStepValidity, data } = inject(
  BULK_ACTIONS_MODAL_CONTEXT,
) as ContextWithData<Ref<{ emails: string[] }>>;

const props = withDefaults(
  defineProps<{
    id: string;
    index: number;
    blockCurrentUserEmail: boolean;
  }>(),
  {
    blockCurrentUserEmail: true,
  },
);

const handleErrors = (e: Array<{ type: string; path: number[] }>) => {
  setStepValidity({
    id: props.id,
    index: props.index,
    isValid: e.length === 0,
    errors: e.map((error) => ({
      code: error.type,
      message: t(`validation.email.${error.type}`),
      field: "emails",
      metadata: { path: error.path },
    })),
  });
};

watch(
  data,
  (updatedData) => {
    if (updatedData.emails.length === 0) {
      setStepValidity({
        id: props.id,
        index: props.index,
        isValid: false,
        errors: [
          {
            code: "required",
            message: t("validation.email.required"),
            field: "emails",
          },
        ],
      });
    }
  },
  { immediate: true, deep: true },
);
</script>
<template>
  <div class="mt-2 md:mt-4">
    <GAlert
      variant="info"
      :message="t('organisation.bulk_action_emails_hint')"
    />
    <div class="w-full mt-2 md:mt-4">
      <GEmailTagTextarea
        :autoFocus="true"
        :modelValue="data.emails"
        :additionalValidationSchemas="
          props.blockCurrentUserEmail ? [blockCurrentUserEmailSchema] : []
        "
        @update:modelValue="data.emails = $event"
        @error="handleErrors($event)"
        placeholder="Enter email addresses..."
      />
    </div>
  </div>
</template>
