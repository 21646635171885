<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { GEmptyState, GSpinner, TextInput, GButton } from "@/components";
import { useTeamsStore } from "@/stores";

import SearchIcon from "@/assets/images/icons/search.svg";
import errorPageImage from "@/assets/images/error-page-image.svg?url";
import PlusIcon from "@/assets/images/icons/plus.svg";

const { t } = useI18n();
const teamsStore = useTeamsStore();
const { teamsList, isFetchingTeams, teamsError, newTeam, newTeamPayload } =
  storeToRefs(teamsStore);
const searchQuery = ref("");

const selectedTeams = defineModel<number[]>({ default: [] });
watch(newTeam, () => {
  if (newTeam.value) {
    selectedTeams.value.push(newTeam.value.Id);
  }
});

const filteredTeams = computed(() => {
  return teamsList.value?.filter((team) =>
    team.Name.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

const isNewTeamSectionOpen = ref(false);
</script>
<template>
  <p>{{ t("organisation.modal_add_to_team_description") }}</p>
  <TextInput
    name="title"
    fullWidth
    :labelClasses="['hidden']"
    :placeholder="`${t('common.search')}...`"
    :label="t('common.search')"
    v-model="searchQuery"
  >
    <template #prefixIcon><SearchIcon /></template>
  </TextInput>
  <div class="flex items-center">
    <span id="teams-list-label">{{ t("common.select_teams") }}</span>
    <span class="ml-1 mr-auto"
      >({{ t("common.count_selected", selectedTeams.length) }})</span
    >

    <GButton
      variant="tertiary"
      @click="isNewTeamSectionOpen = !isNewTeamSectionOpen"
    >
      <template #prefixIcon><PlusIcon /></template>
      {{ t("organisation.new_team") }}</GButton
    >
  </div>
  <div v-if="isNewTeamSectionOpen" class="flex items-center gap-2">
    <TextInput
      name="teamName"
      fullWidth
      :labelClasses="['hidden']"
      :placeholder="`${t('organisation.new_team_name')}`"
      :label="t('organisation.new_team_name')"
      v-model="newTeamPayload.Name"
    ></TextInput>
    <GButton
      @click="teamsStore.addNewTeam"
      :disabled="newTeamPayload.Name === ''"
      >{{ t("common.add") }}</GButton
    >
  </div>
  <ul
    v-if="teamsList"
    aria-labelledby="teams-list-label"
    class="block h-[40vh] overflow-y-auto"
  >
    <li
      v-for="team in filteredTeams"
      :key="team.Id"
      class="flex items-center gap-2 rounded-lg p-4 hover:bg-slate-100"
    >
      <input
        type="checkbox"
        class="checkbox checkbox-primary"
        :value="team.Id"
        v-model="selectedTeams"
      />{{ team.Name }}
      <span class="ml-auto">{{
        t("organisation.team_members_count", { count: team.ActiveUserCount })
      }}</span>
    </li>
  </ul>
  <GSpinner v-if="isFetchingTeams" />
  <GEmptyState
    v-if="teamsError"
    class="mx-auto"
    :title="teamsError"
    :description="teamsError"
    :imageUrl="errorPageImage"
  ></GEmptyState>
</template>
