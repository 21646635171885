import { z, type ZodIssue } from "zod";
import { useUserStore } from "@/stores/user";
import { useI18n } from "vue-i18n";

type EmailErrorType = "invalid" | "duplicate" | "blocked" | "ownEmail";

export const useEmailValidation = () => {
  const { t } = useI18n();
  const userStore = useUserStore();

  const MAX_EMAIL_LENGTH = 254;
  const emailSchema = z.string().toLowerCase().email().max(MAX_EMAIL_LENGTH);

  const isValidEmail = (email: string) => {
    return emailSchema.safeParse(email).success;
  };

  const uniqueEmailListSchema = emailSchema
    .array()
    .superRefine((emails, ctx) => {
      const uniqueValues = new Map<string, number>();
      emails.forEach((email, idx) => {
        const firstAppearanceIndex = uniqueValues.get(email);
        if (firstAppearanceIndex !== undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("validationMessages.duplicate_emails"),
            path: [idx],
            params: {
              errorType: "duplicate",
            },
          });
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("validationMessages.duplicate_emails"),
            path: [firstAppearanceIndex],
            params: {
              errorType: "duplicate",
            },
          });
          return;
        }
        uniqueValues.set(email, idx);
      });
    });

  const blockCurrentUserEmailSchema = emailSchema
    .array()
    .superRefine((emails, ctx) => {
      emails.forEach((email, idx) => {
        if (email === userStore.user?.Email) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("validationMessages.duplicate_emails"),
            path: [idx],
            params: {
              errorType: "ownEmail",
            },
          });
        }
      });
    });

  const composeSchemas = (schemas: z.ZodSchema[]) => {
    if (schemas.length === 1) {
      return schemas[0];
    }

    return schemas.reduce((acc, schema) => {
      return acc.and(schema);
    });
  };

  const getEmailErrorType = (issue: ZodIssue): EmailErrorType => {
    switch (issue.code) {
      case "custom":
        return issue.params.errorType as EmailErrorType;

      case "too_big":
      case "invalid_type":
        return "invalid";

      default:
        return "invalid";
    }
  };

  return {
    blockCurrentUserEmailSchema,
    uniqueEmailListSchema,
    isValidEmail,
    composeSchemas,
    getEmailErrorType,
  };
};
