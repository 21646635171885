import { useRoute } from "vue-router";
import { useFetch } from "@/composables";
import type { UserReportDetails } from "./types";
import { computed, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatSecondsToHHMMSS } from "@/utils/misc";
import { differenceInDays, getMonth, getTime, parseISO } from "date-fns";

type UseUserReportDetailsInput = {
  dateFrom: Ref<string>;
  dateTo: Ref<string>;
};

export const useUserReportDetails = ({
  dateFrom,
  dateTo,
}: UseUserReportDetailsInput) => {
  const { params } = useRoute();
  const { t } = useI18n();

  const payload = computed(() => ({
    PersonId: params.studentId,
    SearchFor: "",
    DateFrom: dateFrom.value,
    DateTo: dateTo.value,
  }));

  const {
    data: userDetails,
    isFetching,
    error,
  } = useFetch("api/ReportStudentDetail", { refetch: true })
    .post(payload)
    .json<UserReportDetails>();

  const statsData = computed(() => {
    if (!userDetails.value) return [];
    return [
      {
        icon: "clock",
        data: [
          {
            label: t("reports.tbl_col_study_time_hhmmss"),
            value: formatSecondsToHHMMSS(userDetails.value.ContentSeconds),
          },
        ],
      },
      {
        icon: "fileCertificate",
        data: [
          {
            label: t("reports.tbl_col_course_started"),
            value: userDetails.value.CoursesStarted.toString(10),
          },
          {
            label: t("reports.tbl_col_course_completed"),
            value: userDetails.value.CoursesCompleted.toString(10),
          },
        ],
      },
      {
        icon: "fileCertificate",
        data: [
          {
            label: t("reports.tbl_col_test_passed"),
            value: userDetails.value.TestsPassed.toString(10),
          },
        ],
      },
    ];
  });

  const formattedActivityVisitsData = computed(() => {
    if (!userDetails.value) {
      return { labels: [], data: [] };
    }

    // if less then 60 days, show days individually
    if (
      differenceInDays(
        parseISO(payload.value.DateTo),
        parseISO(payload.value.DateFrom),
      ) < 60
    ) {
      return {
        labels: userDetails.value.ModuleCount.map(({ dd, mm, yyyy }) =>
          getTime(new Date(`${yyyy}-${mm}-${dd}`)),
        ),
        data: userDetails.value.ModuleCount.map(
          (dataPoint) => dataPoint.modules,
        ),
      };
    }

    const groupedData = groupCountPerMonthAndYear(
      userDetails.value.ModuleCount.map(({ dd, mm, yyyy, modules }) => ({
        date: new Date(`${yyyy}-${mm}-${dd}`),
        count: modules,
      })),
    );
    return {
      labels: Object.keys(groupedData).map((key) => getTime(new Date(key))),
      data: Object.values(groupedData),
    };
  });

  return {
    userDetails,
    isFetching,
    error,
    statsData,
    formattedActivityVisitsData,
  };
};

const groupCountPerMonthAndYear = (
  data: Array<{ date: Date; count: number }>,
): Record<string, number> => {
  const result: Record<string, number> = {};
  for (const dataPoint of data) {
    const date = dataPoint.date;
    const month = getMonth(date) + 1;
    const year = date.getFullYear();
    const key = `${year}-${month}`;
    if (result[key]) {
      result[key] += dataPoint.count;
    } else {
      result[key] = dataPoint.count;
    }
  }
  return result;
};
