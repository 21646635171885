import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import type {
  AnonymiseUsersByIdsResponse,
  ManageUsersTableDataRow,
  UserActionId,
} from "./types";
import { useFetch, useLocale } from "@/composables";
import { useNotificationsStore } from "@/stores";
import { useEmailValidation } from "@/composables/useEmailValidation";

import { USER_STATUS, userStatusValueToKey } from "@/constants/user";

type UserAction = {
  id: UserActionId;
  label: string;
  isAllowed: (selectedUsers: ManageUsersTableDataRow[]) => boolean;
  isDestructive?: boolean;
  action: () => void;
};

export const useUserActionsStore = defineStore("userActions", () => {
  const { t } = useI18n();
  const { locale } = useLocale();
  const { showNotification } = useNotificationsStore();
  const activeAction = ref<UserActionId | null>(null);
  const activeRow = ref<ManageUsersTableDataRow | null>(null);
  const selectedUsers = ref<Array<ManageUsersTableDataRow>>([]);
  const isActionRequestPending = ref(false);
  const { blockCurrentUserEmailSchema } = useEmailValidation();

  const userActions: UserAction[] = [
    {
      id: "resendInvitation",
      label: t("organisation.resend_invitation"),
      action: () => (activeAction.value = "resendInvitation"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some((user) => user.status === USER_STATUS.invited),
    },
    {
      id: "revokeInvitation",
      label: t("organisation.revoke_invitation"),
      action: () => (activeAction.value = "revokeInvitation"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some((user) => user.status === USER_STATUS.invited),
    },
    {
      id: "addToTeam",
      label: t("organisation.add_to_team"),
      action: () => (activeAction.value = "addToTeam"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some(
          (user) =>
            user.status === USER_STATUS.invited ||
            user.status === USER_STATUS.active,
        ),
    },
    {
      id: "assignRole",
      label: t("organisation.assign_role"),
      action: () => (activeAction.value = "assignRole"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some(
          (user) =>
            user.status === USER_STATUS.invited ||
            user.status === USER_STATUS.active,
        ),
    },
    {
      id: "resetPassword",
      label: t("organisation.reset_password"),
      action: () => (activeAction.value = "resetPassword"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some((user) => user.status === USER_STATUS.active),
    },
    {
      id: "export",
      label: t("common.export"),
      action: () => (activeAction.value = "export"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some(
          (user) =>
            user.status === USER_STATUS.invited ||
            user.status === USER_STATUS.active ||
            user.status === USER_STATUS.blocked,
        ),
    },
    {
      id: "unblockUser",
      label: t("organisation.unblock"),
      action: () => (activeAction.value = "unblockUser"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some((user) => user.status === USER_STATUS.blocked),
    },
    {
      id: "anonymiseUser",
      label: t("organisation.anonymise"),
      action: () => (activeAction.value = "anonymiseUser"),
      isAllowed: (selectedUsers) => {
        const selfAnonymisingResult = blockCurrentUserEmailSchema.safeParse(
          selectedUsers.map((user) => user.email),
        );

        if (!selfAnonymisingResult.success) {
          return false;
        }

        return selectedUsers.some(
          (user) =>
            user.status === USER_STATUS.invited ||
            user.status === USER_STATUS.active ||
            user.status === USER_STATUS.blocked,
        );
      },
      isDestructive: true,
    },
    {
      id: "blockUser",
      label: t("organisation.block"),
      action: () => (activeAction.value = "blockUser"),
      isAllowed: (selectedUsers) =>
        selectedUsers.some(
          (user) =>
            user.status === USER_STATUS.invited ||
            user.status === USER_STATUS.active,
        ),
      isDestructive: true,
    },
  ];

  const closeModal = () => {
    activeAction.value = null;
    activeRow.value = null;
  };

  // if the user is opening a row action menu, we need to get the id of the row
  // that is being opened. If the user is selecting multiple rows, we need to
  // get the ids of the selected rows
  const eligibleIdsForAction = computed(() => {
    if (activeRow.value) return [activeRow.value.id];
    return selectedUsers.value.map((user) => user.id);
  });

  const actionsForSelectedRows = computed(() => {
    const selectedItemsStatuses = selectedUsers.value.map(
      (item) => item.status,
    );

    if (selectedItemsStatuses.length === 0) return userActions;

    return userActions.filter((action) =>
      action.isAllowed(selectedUsers.value),
    );
  });

  const actionsForRow = computed(() => {
    if (!activeRow.value) return userActions;

    return userActions.filter((action) => action.isAllowed([activeRow.value]));
  });

  const actionsForBulk = computed(() => {
    return userActions;
  });

  const revokeInvitationsByIds = async () => {
    const isSomeSelectedUsersInvited = selectedUsers.value.some(
      (user) => user.status === USER_STATUS.invited,
    );

    if (!isSomeSelectedUsersInvited) {
      return;
    }

    isActionRequestPending.value = true;
    const { error: revokeInvitationsError, response } = await useFetch(
      "/api/ActivityQueue",
    ).put({
      command: "revoke_by_voucherid",
      // TODO: Ask BE whether we should filter out the users that are not invited
      // on the client side or we just send the whole list of users
      data: eligibleIdsForAction.value.join(","),
      languagecode: locale.value,
    });
    isActionRequestPending.value = false;

    if (revokeInvitationsError.value) {
      showNotification({
        type: "danger",
        message: t("organisation.revoke_invitation_error", {
          n: selectedUsers.value.length || 1,
          reason: (await response.value.json())?.title,
        }),
      });
    } else {
      showNotification({
        type: "success",
        message: t("organisation.revoke_invitation_success"),
      });
    }

    activeAction.value = null;
  };

  const anonymiseUsersByIds = async () => {
    const selfAnonymisingResult = blockCurrentUserEmailSchema.safeParse(
      selectedUsers.value.map((user) => user.email),
    );

    if (!selfAnonymisingResult.success) {
      showNotification({
        type: "danger",
        message: t("validationMessages.own_email"),
      });
      return;
    }

    isActionRequestPending.value = true;
    const { data } = await useFetch("/api/AnonymizeUser/ByUserIds")
      .post({
        personIds: eligibleIdsForAction.value,
      })
      .json<AnonymiseUsersByIdsResponse>();
    isActionRequestPending.value = false;

    if (data.value.enqueuedEntriesCount > 0) {
      showNotification({
        type: "success",
        message: t("organisation.anonymise_success", {
          n: data.value.enqueuedEntriesCount,
        }),
      });
    }

    if (data.value.excludedEntries.length > 0) {
      showNotification({
        type: "danger",
        message: t("organisation.anonymise_error", {
          n: data.value.excludedEntries.length,
          reason: data.value.excludedEntries[0].reason,
        }),
      });
    }

    activeAction.value = null;
  };

  const blockUsersByIds = async () => {
    isActionRequestPending.value = true;

    const { error: blockUsersError } = await useFetch(
      "/api/ActivityQueue",
    ).delete({
      command: "remove_user_by_personid",
      data: eligibleIdsForAction.value.join(","),
      languageCode: locale.value,
      removeMailTxt: "",
    });
    isActionRequestPending.value = false;

    if (blockUsersError.value) {
      showNotification({
        type: "danger",
        message: blockUsersError.value,
      });
    } else {
      showNotification({
        type: "success",
        message: t("organisation.block_success"),
      });
    }

    activeAction.value = null;
    if (activeRow.value !== null) {
      activeRow.value = null;
    }
  };

  const addUsersToTeams = async (teamIds: number[], userIds: []) => {
    isActionRequestPending.value = true;

    const { error: addUsersToTeamError } = await useFetch(
      "/api/TagPerson",
    ).post({
      TagId: teamIds,
      Email: userIds,
      StartDate: new Date().toISOString(),
      DurationUnit: "YYYY",
      Duration: 100,
    });
    isActionRequestPending.value = false;

    if (addUsersToTeamError.value) {
      showNotification({
        type: "danger",
        message: addUsersToTeamError.value,
      });
    } else {
      showNotification({
        type: "success",
        message: t("organisation.add_to_team_success"),
      });
    }

    activeAction.value = null;
  };

  const exportUsersFileHeaders = computed(() => [
    t("reports.tbl_col_email"),
    t("common.name"),
    t("common.status"),
    // t("reports.team"), // TODO: not available from the BE yet
    // t("common.last_active"), // TODO: not available from the BE yet
  ]);

  const exportUsersFileRows = () => {
    return selectedUsers.value.map((user) => [
      user.email,
      user.name,
      userStatusValueToKey[user.status],
      // user.team, // TODO: not available from the BE yet
      // user.lastActive, // TODO: not available from the BE yet
    ]);
  };

  return {
    actionsForSelectedRows,
    actionsForRow,
    actionsForBulk,
    addUsersToTeams,
    revokeInvitationsByIds,
    anonymiseUsersByIds,
    blockUsersByIds,
    closeModal,
    activeAction,
    activeRow,
    selectedUsers,
    isActionRequestPending,
    exportUsersFileHeaders,
    exportUsersFileRows,
  };
});

export default useUserActionsStore;
