<script setup lang="ts">
import { watch, inject, type Ref } from "vue";

import ChooseTeams from "../ChooseTeams.vue";
import { BULK_ACTIONS_MODAL_CONTEXT } from "../BulkActionsModal/context";
import type { ContextWithData } from "../BulkActionsModal/types";
import type { BulkAddToTeamsData } from "./types";

const { data, setStepValidity } = inject(
  BULK_ACTIONS_MODAL_CONTEXT,
) as ContextWithData<Ref<BulkAddToTeamsData>>;
const props = defineProps<{
  id: string;
  index: number;
}>();

watch(
  () => data.value.teamIds,
  (selectedTeams) => {
    setStepValidity({
      id: props.id,
      index: props.index,
      isValid: selectedTeams.length > 0,
    });
  },
  { immediate: true },
);
</script>
<template>
  <ChooseTeams v-model="data.teamIds" />
</template>
