<script lang="ts" setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import ChooseTeamsStep from "./ChooseTeamsStep.vue";
import type { BulkAddToTeamsData } from "./types";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { useTeamsStore } from "@/stores";
import { storeToRefs } from "pinia";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
    additionalProps: {
      blockCurrentUserEmail: false,
    },
  },
  {
    id: "choose-teams",
    component: ChooseTeamsStep,
  },
];

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const addToTeamsData = ref<BulkAddToTeamsData>({
  emails: [],
  teamIds: [],
});

const { t } = useI18n();

const teamsStore = useTeamsStore();
const { addToTeamsResponseStatusCode, isAddToTeamsFetching } =
  storeToRefs(teamsStore);
const { addStudentsToTeams } = teamsStore;

watch(addToTeamsResponseStatusCode, (statusCode) => {
  if (statusCode === 200) {
    addToTeamsData.value = {
      emails: [],
      teamIds: [],
    };
    emit("close");
  }
});

const handleSubmit = async () => {
  await addStudentsToTeams(
    addToTeamsData.value.teamIds,
    [],
    addToTeamsData.value.emails,
  );
};
</script>
<template>
  <BulkActionsModal
    class="w-1/2"
    :isOpen="props.isOpen"
    :data="addToTeamsData"
    :steps="steps"
    :title="t('organisation.add_to_team')"
    :confirmButtonProps="{
      label: t('organisation.add_to_team', addToTeamsData.teamIds.length),
      variant: 'primary',
      isLoading: isAddToTeamsFetching,
    }"
    @confirm="handleSubmit()"
    @close="emit('close')"
  />
</template>
