import { computed, watch, type ComputedRef, ref, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import type { TableColumn } from "@/patterns";
import { useLocale, type FilterDefinition } from "@/composables";
import {
  useAssessmentsStore,
  useExportStore,
  useFlagStore,
  useToastStore,
  useWebSessionStore,
} from "@/stores";
import { exportToCSV, exportToExcel, formatLtiData } from "@/utils/export";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";
import { createAsmFetch } from "@/composables/useFetch";
import type {
  AssessmentExportTableData,
  AssessmentMetadata,
  AssessmentTableDataRow,
  AssessmentTableResponse,
  ColumnAccessor,
} from "./types";
import {
  useDownloadScorm,
  defaultTemplateAttributes,
  removeSpecialCharactersAndAccents,
} from "../components/useDownloadScorm";
import {
  RemoteScormContentType,
  type ContentRemoteScorm,
  type ContentRS,
  type MultiLanguageBundledContentRS,
} from "@/types/export";

export const useAssessmentsTab = (asmLocale: Ref<string>) => {
  const { t } = useI18n();
  const { locale } = useLocale();
  const assessmentTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "thumbnail",
      headerLabel: t("common.thumbnail"),
      accessor: "thumbnail",
      sortable: false,
      smallScreenPosition: "last",
    },
    {
      id: "title",
      headerLabel: t("common.name"),
      accessor: "title",
      smallScreenPosition: "center",
      isGloballyFilterable: true,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];
  const assessmentExportTableColumns = [
    "assessmentId",
    "assessmentName",
    "url",
    "lti11LaunchUrl",
    "lti13LaunchUrl",
    "assessmentTypeId",
    "description",
    "duration",
    "imageIcon",
    "imageUrlLandScape",
    "imageUrlPortrait",
    "imageUrlSquare",
    "locale",
    "publishDate",
    "tileBackgroundColor",
  ];

  const flagStore = useFlagStore();

  const useAsmFetch = createAsmFetch();
  const { isFetching, data, error, execute } = useAsmFetch(
    `/api/AssessmentLibrary`,
    {
      async beforeFetch({ options }) {
        options.headers = {
          ...options.headers,
          locale: asmLocale.value,
        };
        return {
          options,
        };
      },
    },
  ).json<AssessmentTableResponse[]>();

  const assessmentData = computed(
    () =>
      data.value
        ?.filter(
          (assessment) =>
            assessment.publicationStatus === "Published" &&
            assessment.accessRight === "startable",
        )
        .map((assessment) => ({
          id: assessment.assessmentId,
          title: assessment.title,
          thumbnail: assessment.tile?.backgroundImage?.uri,
          backgroundShape: assessment.tile?.backgroundShape,
          backgroundColor: assessment.tile?.backgroundColor,
          backgroundShapePosition: assessment.tile?.backgroundShapePosition,
          assessmentLink: `${import.meta.env.VITE_EDUCATION_WEBSITE}/${locale.value}/assessments/${assessment.assessmentId}`,
        })) ?? [],
  );

  const {
    data: asmMetaData,
    isFetching: isFetchingAsmMetaData,
    error: errorAsmMetaData,
  } = useAsmFetch(`/api/AssessmentMetadata`).json<AssessmentMetadata[]>();

  const filterDefinition: ComputedRef<
    (FilterDefinition<ColumnAccessor> | FilterDefinition<string>)[]
  > = computed(() => {
    return [
      {
        id: "title",
        type: "search",
        startingValue: "",
        label: t("common.search"),
      },
    ];
  });

  watch(asmLocale, () => {
    execute();
  });

  const selectedRows = ref<AssessmentTableDataRow[]>([]);

  const exportableData = computed(() => {
    const columns: { id: string; header: string }[] = [];
    const dataSource: AssessmentExportTableData[] = [];

    if (asmMetaData.value && selectedRows.value.length > 0) {
      assessmentExportTableColumns.forEach((key) => {
        if (
          key !== "lti13LaunchUrl" ||
          flagStore.shouldForwardLti13ToLmsConnectorFlag
        ) {
          columns.push({
            id: key,
            header: key,
          });
        }
      });

      selectedRows.value.forEach((row) => {
        const rowMeta = asmMetaData.value?.find(
          (asm) =>
            asm.assessmentId === row.id && asm.locale === asmLocale.value,
        );
        if (rowMeta) {
          // DASH 541 temporary fixes slashes at the start of urls
          rowMeta.imageIcon
            ? (rowMeta.imageIcon = rowMeta.imageIcon.replace(/^\/{2}/, ""))
            : null;
          rowMeta.url
            ? (rowMeta.url = rowMeta.url.replace(/^\/{2}/, ""))
            : null;
          rowMeta.imageUrlLandScape
            ? (rowMeta.imageUrlLandScape = rowMeta.imageUrlLandScape.replace(
                /^\/{2}/,
                "",
              ))
            : null;
          rowMeta.imageUrlPortrait
            ? (rowMeta.imageUrlPortrait = rowMeta.imageUrlPortrait.replace(
                /^\/{2}/,
                "",
              ))
            : null;
          rowMeta.imageUrlSquare = rowMeta.imageUrlSquare.replace(/^\/{2}/, "");

          // DASH 545 temporary fix not all asm have locale queryparam
          const ltiLaunchUrl = checkAndAddLocale(
            rowMeta.ltiLaunchUrl,
            rowMeta.locale,
          );

          dataSource.push({
            assessmentId: rowMeta.assessmentId,
            assessmentTypeId: rowMeta.assessmentTypeId,
            assessmentName: rowMeta.assessmentName,
            description: rowMeta.description,
            duration: rowMeta.duration,
            imageIcon: rowMeta.imageIcon,
            imageUrlLandScape: rowMeta.imageUrlLandScape,
            imageUrlPortrait: rowMeta.imageUrlPortrait,
            imageUrlSquare: rowMeta.imageUrlSquare,
            locale: rowMeta.locale,
            lti11LaunchUrl: ltiLaunchUrl,
            lti13LaunchUrl: ltiLaunchUrl,
            publishDate: rowMeta.publishDate,
            tileBackgroundColor: rowMeta.tileBackgroundColor,
            url: rowMeta.url,
          });
        }
      });
    }
    return { columns, dataSource };
  });

  function checkAndAddLocale(url: string, locale: string): string {
    const launchUrl = new URL(url);
    if (!launchUrl.searchParams.has("locale")) {
      launchUrl.searchParams.append("locale", locale);
    }

    return launchUrl.toString();
  }

  const exportStore = useExportStore();
  const {
    shouldIncludeMetadata,
    selectedFileFormat,
    shouldExportMultiLanguage,
  } = storeToRefs(exportStore);

  const {
    downloadScormPackages,
    getLanguageOptions,
    downloadRemoteScormPackages,
  } = useDownloadScorm();
  const { getLanguageCodeByLanguageRegion } = useWebSessionStore();

  const formatScormAssessmentData = (
    assessments: AssessmentMetadata[],
  ): ContentRS[] => {
    return assessments.map((assessment) => ({
      id: assessment.assessmentId,
      title: assessment.assessmentName,
      name: assessment.assessmentName,
      locale: assessment.locale,
      language: getLanguageCodeByLanguageRegion(assessment.locale),
    }));
  };

  const getMultiLanguageAssessmentWrapperName = (
    assessments: AssessmentMetadata[],
  ): string => {
    const { assessmentName, assessmentId } =
      getMultiLanguageDefaultAssessment(assessments);
    const containerName = removeSpecialCharactersAndAccents(
      `${assessmentId.split("_")[0]}-multi-lang-${assessmentName}`.trim(),
    ).toLowerCase();
    return containerName;
  };

  const getMultiLanguageDefaultAssessment = (
    assessments: AssessmentMetadata[],
  ): AssessmentMetadata => {
    const currentLocale = locale.value;
    return (
      assessments.find((assessment) => assessment.locale === currentLocale) ||
      assessments.find(
        (assessment) => assessment.locale.split("-")[0].toUpperCase() === "EN",
      ) ||
      assessments[0]
    );
  };

  const formatScormAssessmentList = ():
    | ContentRS[]
    | MultiLanguageBundledContentRS[] => {
    if (shouldExportMultiLanguage.value) {
      const assessmentContianerIds = exportableData.value.dataSource.map(
        (row) => row.assessmentId.split("_")[0],
      );
      const uniqueAssessmentContainerIds = [...new Set(assessmentContianerIds)];
      return uniqueAssessmentContainerIds.map((containerId) => {
        const assessmentsInContainer = asmMetaData.value.filter(
          (row) => row.assessmentId.split("_")[0] === containerId,
        );
        const contentList = formatScormAssessmentData(assessmentsInContainer);
        const item = {
          containerName: getMultiLanguageAssessmentWrapperName(
            assessmentsInContainer,
          ),
          contentListInContainer: contentList,
          languageOptionList: getLanguageOptions(contentList),
        };
        return item;
      });
    } else {
      const assessmentList = exportableData.value.dataSource.map((row) =>
        asmMetaData.value.find(
          (assessment) => assessment.assessmentId === row.assessmentId,
        ),
      );
      return formatScormAssessmentData(assessmentList);
    }
  };

  const formatRemoteScormAssessmentList = (): ContentRemoteScorm[] => {
    const assessmentList = exportableData.value.dataSource.map((row) =>
      asmMetaData.value.find(
        (assessment) => assessment.assessmentId === row.assessmentId,
      ),
    );
    if (shouldExportMultiLanguage.value) {
      const assessmentContianerIds = exportableData.value.dataSource.map(
        (row) => row.assessmentId.split("_")[0],
      );
      const uniqueAssessmentContainerIds = [...new Set(assessmentContianerIds)];
      return uniqueAssessmentContainerIds.map((containerId) => {
        const assessmentsInContainer = asmMetaData.value.filter(
          (row) => row.assessmentId.split("_")[0] === containerId,
        );
        return {
          containerName: getMultiLanguageAssessmentWrapperName(
            assessmentsInContainer,
          ),
          contentType: RemoteScormContentType.Assessment,
          contentId: containerId,
          isMultipleLanguage: true,
          title: getMultiLanguageDefaultAssessment(assessmentsInContainer)
            .assessmentName,
          locale: locale.value,
        };
      });
    } else {
      return assessmentList.map((assessment) => ({
        contentId: assessment.assessmentId,
        contentType: RemoteScormContentType.Assessment,
        isMultipleLanguage: false,
        title: assessment.assessmentName,
        locale: assessment.locale,
      }));
    }
  };

  const exportToScorm = async () => {
    const asmStore = useAssessmentsStore();
    const toastStore = useToastStore();
    if (!asmStore.isAssessmentsUser) {
      toastStore.addToast({
        header: t("error.could_not_download"),
        body: t("error.could_not_download_rights"),
      });
      return;
    }
    if (flagStore.scromWithLMSConnectorFlag) {
      const coursesToDownload = formatRemoteScormAssessmentList();
      await downloadRemoteScormPackages(coursesToDownload);
    } else {
      const assessmentToDownload = formatScormAssessmentList();

      await downloadScormPackages(
        "ASSESSMENT",
        assessmentToDownload,
        defaultTemplateAttributes,
      );
    }

    if (shouldIncludeMetadata.value) {
      const metadata = formatLtiData(exportableData.value);
      if (selectedFileFormat.value === "csv") exportToCSV(metadata, "metadata");
      else exportToExcel(metadata, "metadata");
    }

    trackExportAssessments(selectedRows.value);
    selectedRows.value = [];
  };

  const trackExportAssessments = (
    selectedActivities: AssessmentTableDataRow[],
  ) => {
    trackMixPanelEvent("assessmentsExport", {
      count: selectedActivities.length,
      activities: selectedActivities.map((activity) => ({
        id: activity.id,
        name: activity.title,
      })),
      context: "Connect Page",
    });
  };

  return {
    isFetching,
    error,
    errorAsmMetaData,
    isFetchingAsmMetaData,
    assessmentTableDefinition,
    assessmentData,
    selectedRows,
    filterDefinition,
    exportToScorm,
    exportableData,
    trackExportAssessments,
  };
};
