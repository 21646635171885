<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { GAlert, TextInput } from "@/components";

const { t } = useI18n();

const confirmationText = defineModel<string>({ default: "" });
</script>
<template>
  <div class="grid gap-6">
    <p class="text-grey-80">
      {{ t("organisation.modal_block_description") }}
    </p>
    <GAlert
      :dismissible="false"
      variant="danger"
      :message="t('organisation.modal_block_warning')"
    />
    <i18n-t keypath="organisation.modal_block_confirm" tag="label" class="mb-2">
      <template #input>
        <span class="font-medium uppercase">{{
          t("organisation.block").toUpperCase()
        }}</span>
      </template>
    </i18n-t>
  </div>
  <TextInput
    name="block-confirm-text"
    v-model="confirmationText"
    label=""
    aria-labelledby="block_confirm"
  />
</template>
