<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { GButton, GModal } from "@/components";
import useUserActionsStore from "../store";
import ChooseTeams from "./ChooseTeams.vue";

const { t } = useI18n();
const userActionsStore = useUserActionsStore();
const { activeAction } = storeToRefs(userActionsStore);
const { closeModal } = userActionsStore;

const selectedTeams = ref<number[]>([]);
</script>
<template>
  <GModal
    :isOpen="activeAction === 'addToTeam'"
    scrollBehaviour="modal"
    :title="t('organisation.add_to_team')"
    @close="closeModal()"
    data-testId="add-to-team-modal"
  >
    <ChooseTeams v-model="selectedTeams" />

    <template #actions>
      <GButton @click="closeModal" :disabled="selectedTeams.length === 0">{{
        t("organisation.add_to_team")
      }}</GButton>
    </template>
  </GModal>
</template>
