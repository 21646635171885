<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { GButton, GModal } from "@/components";
import { useUserActionsStore } from "../store";
import { storeToRefs } from "pinia";
import AnonymiseConfirmation from "./AnonymiseConfirmation.vue";

const confirmationText = ref("");
const { selectedUsers, anonymiseUsersByIds, closeModal } =
  useUserActionsStore();
const { activeAction } = storeToRefs(useUserActionsStore());
const { t } = useI18n();
</script>
<template>
  <GModal
    :isOpen="activeAction === 'anonymiseUser'"
    scrollBehaviour="modal"
    :title="t('organisation.anonymise_user(s)', selectedUsers.length || 1)"
    @close="closeModal()"
    data-testId="anonymise-modal"
    class="max-w-[30rem]"
  >
    <AnonymiseConfirmation v-model="confirmationText" />
    <template #actions>
      <GButton variant="link" @click="closeModal">
        {{ t("common.cancel") }}
      </GButton>
      <GButton
        variant="danger"
        @click="anonymiseUsersByIds"
        :disabled="
          confirmationText.toLocaleLowerCase() !==
          t('organisation.anonymise').toLocaleLowerCase()
        "
      >
        {{ t("organisation.anonymise") }}
      </GButton>
    </template>
  </GModal>
</template>
