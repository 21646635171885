<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { format, formatISO } from "date-fns";
import {
  GButton,
  GDatePicker,
  GExportModal,
  GRadioGroup,
  GStateHandlerWrapper,
  GAlert,
  GDropdown,
} from "@/components";
import { GTable, FilterPanel } from "@/patterns";
import TeamsSelect from "@/patterns/TeamsSelect/TeamsSelect.vue";
import { useUsersReport } from "./useUsersReport";
import { useFlagStore } from "@/stores";
import { reportInitialDateRange } from "../utils";
import type { Filters, UsersTableRow } from "./types";

import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const { t } = useI18n();
const flagsStore = useFlagStore();

const defaultSort = [{ desc: true, id: "studyTime" }];
const initialFilters: Filters = {
  search: "",
  status: -1,
  teams: [null],
  date: reportInitialDateRange,
};
const reportVariantOptions = [
  { label: t("usersReport.export_modal_report_variant_users"), value: "users" },
  {
    label: t(
      "usersReport.export_modal_report_variant_learning_progress_details",
    ),
    value: "learningProgressDetails",
  },
];

const isSecondaryFiltersOpen = ref(false);
const isExportModalOpen = ref(false);
const selectedRows = ref<UsersTableRow[]>([]);
const filters = ref<Filters>({ ...initialFilters });
const selectedReportVariant = ref<"users" | "learningProgressDetails">("users");

const {
  isFetching,
  error,
  tableDefinition,
  data,
  hasReportTeamEntitlement,
  hasForeignUsername,
  isUserReporter,
  executeLearningProgressDetailsReportFetch,
  fetchLearningProgressDetailsReportDateRange,
  learningProgressDetailsReport,
  isFetchingLearningProgressDetailsReport,
  userStatusOptions,
} = useUsersReport(filters);

const exportFileHeaders = computed(() => [
  t("reports.tbl_col_email"),
  t("reports.tbl_col_name"),
  ...(hasReportTeamEntitlement.value ? [t("reports.teams")] : []),
  t("reports.tbl_col_lesson_visits"),
  t("reports.tbl_col_study_time"),
  t("reports.tbl_col_course_started"),
  t("reports.tbl_col_course_completed"),
  t("reports.tbl_col_test_passed"),
  t("usersReport.tbl_col_has_good_scan"),
  t("reports.studentId"),
  ...(hasForeignUsername.value ? [t("reports.tbl_col_foreign_username")] : []),
]);

const learningProgressDetailsReportCSVHeaders = computed(() => [
  t("reports.tbl_col_company_name"),
  t("reports.tbl_col_course_id"),
  t("reports.tbl_col_course_language"),
  t("reports.tbl_col_course_name"),
  t("reports.tbl_col_course_start"),
  t("reports.tbl_col_username"),
  t("reports.tbl_col_email"),
  t("reports.tbl_col_foreign_username"),
  t("reports.tbl_col_progress"),
  t("reports.tbl_col_study_time"),
  t("reports.tbl_col_lesson_certificate"),
  t("reports.tbl_col_test_passed"),
]);

const toExportHeaders = computed(() =>
  selectedReportVariant.value === "learningProgressDetails"
    ? learningProgressDetailsReportCSVHeaders.value
    : exportFileHeaders.value,
);

const toExportRows = computed(() => {
  if (selectedReportVariant.value === "learningProgressDetails") {
    return learningProgressDetailsReport.value.map((row) => Object.values(row));
  }

  return selectedRows.value.map((row) => {
    const exportRow = { ...row };
    if (!hasReportTeamEntitlement.value) {
      delete exportRow.teams;
    }
    return Object.values(exportRow).map((v) => v.toString());
  });
});

const getReportDetailsPageQuery = (name: string) => {
  const query = new URLSearchParams();
  query.append("name", name);
  query.set(
    "dateFrom",
    formatISO(filters.value.date[0], { representation: "date" }),
  );
  query.set(
    "dateTo",
    formatISO(filters.value.date[1], { representation: "date" }),
  );
  return query.toString();
};
</script>
<template>
  <GStateHandlerWrapper :isLoading="isFetching" :error="error">
    <GButton
      v-if="!flagsStore.actionBarFlag"
      class="mb-4 ml-auto lg:px-12"
      @click="isExportModalOpen = true"
      :disabled="selectedRows.length === 0"
      data-testid="export-users-report-btn"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>
    <FilterPanel
      v-model:search="filters.search"
      :isSecondaryFiltersVisible="isSecondaryFiltersOpen"
      @toggleSecondaryFilters="isSecondaryFiltersOpen = !isSecondaryFiltersOpen"
      @reset="filters = { ...initialFilters }"
    >
      <template #primaryFilters>
        <GDatePicker
          v-model="filters.date"
          :label="t('common.date')"
          class="w-72"
        />
      </template>
      <template #secondaryFilters>
        <GDropdown
          :label="t('common.select_status')"
          :options="userStatusOptions"
          :value="filters.status"
          @change="filters.status = $event as number"
        />

        <TeamsSelect
          v-if="hasReportTeamEntitlement"
          v-model="filters.teams"
          :restrictedToMyTeams="!isUserReporter"
        />
      </template>
    </FilterPanel>
    <GTable
      v-if="data"
      data-testid="users-report-table"
      :data="data"
      :columns="tableDefinition"
      :selectedList="selectedRows"
      @selectedListChange="(list) => (selectedRows = list)"
      :default-sort="defaultSort"
      :exportFn="() => (isExportModalOpen = true)"
    >
      <template v-slot:teams="{ value }">
        <li v-for="team in value as string[]" :key="team" class="list-none">
          {{ team }}
        </li>
      </template>
      <template v-slot:report="{ row }">
        <GButton
          :href="`/admin-dashboard/reports/students/${row.studentId}?${getReportDetailsPageQuery(row.name)}`"
          size="small"
          data-testid="detailed-report-link"
        >
          {{ t("reports.report") }}
        </GButton>
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <GExportModal
    class="w-[25rem]"
    :isOpen="isExportModalOpen"
    fileName="GH - students report"
    :isLoading="isFetchingLearningProgressDetailsReport"
    :onBeforeExport="
      selectedReportVariant === 'learningProgressDetails'
        ? executeLearningProgressDetailsReportFetch
        : undefined
    "
    :data="{ headers: toExportHeaders, rows: toExportRows }"
    @close="isExportModalOpen = false"
  >
    <GRadioGroup
      v-if="flagsStore.userReportExportContentProgressFlag"
      class="mb-2"
      v-model="selectedReportVariant"
      :options="reportVariantOptions"
      name="selectedReportVariant"
      ><template #label>
        {{ t("usersReport.export_modal_export_type") }}
      </template>
    </GRadioGroup>
    <GAlert
      data-testid="learning-progress-period-info"
      :dismissible="false"
      variant="info"
      class="mb-2"
      v-if="selectedReportVariant === 'learningProgressDetails'"
      :message="`${t('reports.period')}: ${format(fetchLearningProgressDetailsReportDateRange.dateFrom, 'P')} - ${format(fetchLearningProgressDetailsReportDateRange.dateTo, 'P')}`"
    />
    <GAlert
      :dismissible="false"
      variant="info"
      v-if="selectedReportVariant === 'learningProgressDetails'"
      :message="
        t('usersReport.export_modal_learning_progress_details_info_message')
      "
    />
  </GExportModal>
</template>
