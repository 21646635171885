<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import {
  GButton,
  GStateHandlerWrapper,
  TileBackground,
  GRestrictedTabView,
} from "@/components";
import { useAssessmentsStore, useExportStore, useFlagStore } from "@/stores";
import { FilterPanel, GTable } from "@/patterns";
import { useAssessmentsTab } from "./useAssessmentsTab";
import ExportModal from "../components/ExportModal.vue";
import AssessmentsLocalesSelect from "../components/AssessmentsLocalesSelect.vue";

import ExternalLinkIcon from "@/assets/images/icons/external-link.svg";
import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const initialFilters = {
  search: "",
  locale: "en-GB",
};

const flagStore = useFlagStore();
const isExportModalOpen = ref(false);
const isFetchingLocale = ref(true);
const localeError = ref(null);
const filters = ref({ ...initialFilters });

const localeRef = computed(() => filters.value.locale);
const { t } = useI18n();
const asmStore = useAssessmentsStore();
const exportStore = useExportStore();
const { multiLangSCOExportAssessmentFlag } = storeToRefs(flagStore);

const { shouldIncludeMetadata, selectedExportType, shouldExportMultiLanguage } =
  storeToRefs(exportStore);
const {
  assessmentTableDefinition,
  isFetching,
  error,
  assessmentData,
  errorAsmMetaData,
  selectedRows,
  isFetchingAsmMetaData,
  exportToScorm,
  exportableData,
  trackExportAssessments,
} = useAssessmentsTab(localeRef);

const defaultSort = [{ desc: false, id: "title" }];

const showMultiLanguageScoExport = computed(() => {
  return (
    selectedExportType.value === "scorm" &&
    multiLangSCOExportAssessmentFlag.value
  );
});

const onStartExport = () => {
  if (selectedRows.value.length > 0) {
    isExportModalOpen.value = true;
  }
};

const handleLTIDownloaded = () => {
  trackExportAssessments(selectedRows.value);
  selectedRows.value = [];
};
</script>
<template>
  <GRestrictedTabView v-if="!asmStore.isAssessmentsFullUser" />

  <GStateHandlerWrapper
    v-else
    :isLoading="isFetching || isFetchingAsmMetaData"
    :error="error || localeError ? {} : null"
  >
    <GButton
      v-if="!flagStore.actionBarFlag"
      class="mb-4 ml-auto lg:px-12"
      @click="onStartExport"
      :disabled="selectedRows.length === 0"
    >
      <template #prefixIcon><ArrowDownToLineIcon /></template>
      {{ t("common.export") }}
    </GButton>
    <FilterPanel
      v-model:search="filters.search"
      @reset="filters = { ...initialFilters }"
    >
      <template #primaryFilters>
        <AssessmentsLocalesSelect
          v-model="filters.locale"
          @ready="isFetchingLocale = false"
          @error="localeError = $event"
        />
      </template>
    </FilterPanel>
    <GTable
      :data="assessmentData || []"
      :columns="assessmentTableDefinition"
      :selectedList="selectedRows"
      @selectedListChange="
        (list) => selectedRows.splice(0, selectedRows.length, ...list)
      "
      :filter="{
        global: filters.search,
      }"
      :default-sort="defaultSort"
      :exportFn="onStartExport"
    >
      <template v-slot:title="cellProps">
        {{ cellProps.value }}
      </template>
      <template v-slot:thumbnail="cellProps">
        <div class="relative w-14">
          <TileBackground
            :shape="cellProps.row.backgroundShape"
            :color="cellProps.row.backgroundColor"
            :position="cellProps.row.backgroundShapePosition"
          />
          <img
            :src="cellProps.value as string"
            class="absolute bottom-0 left-1/2 -translate-x-1/2"
            :alt="cellProps.row?.title"
          />
        </div>
      </template>
      <template v-slot:actions="cellProps">
        <a
          :href="cellProps.row.assessmentLink"
          target="_blank"
          rel="noopener noreferrer"
          class="flex w-max items-center border-b border-transparent text-primary hover:border-b hover:border-dotted hover:border-primary"
        >
          <span class="mr-1">{{ t("common.open") }}</span>
          <ExternalLinkIcon class="h-4 w-4" />
        </a>
      </template>
    </GTable>
  </GStateHandlerWrapper>
  <ExportModal
    :isOpen="isExportModalOpen"
    ltiFileName="gh-assessments-export"
    :data="exportableData"
    :exportTypes="['lti', 'scorm']"
    @close="isExportModalOpen = false"
    @exportToScorm="exportToScorm"
    @exportToLtiSuccess="handleLTIDownloaded"
  >
    <span v-if="showMultiLanguageScoExport" class="flex items-center gap-2"
      ><input
        type="checkbox"
        v-model="shouldExportMultiLanguage"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.export_multi_lang") }}</span
    >
    <span
      v-if="selectedExportType === 'scorm' && !errorAsmMetaData"
      class="flex items-center gap-2 border-t py-4"
      ><input
        type="checkbox"
        v-model="shouldIncludeMetadata"
        class="checkbox checkbox-primary checkbox-sm"
      />
      {{ t("contentSelection.add_content_meta") }}</span
    >
  </ExportModal>
</template>
