<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "@/stores";
import { useFetch, useLocale } from "@/composables";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
  },
];

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const revokeInvitationData = ref<{ emails: string[] }>({
  emails: [],
});

const { locale } = useLocale();
const payload = computed(() => {
  const emails = revokeInvitationData.value.emails.join(";");
  return {
    command: "revoke_by_email",
    data: emails,
    languagecode: locale.value,
  };
});

const { t } = useI18n();
const { showNotification } = useNotificationsStore();
const { isFetching, execute, onFetchResponse, onFetchError } = useFetch(
  "/api/ActivityQueue",
  { immediate: false },
).put(payload);

onFetchResponse(async (response) => {
  if (response.ok) {
    const data = await response.json();
    revokeInvitationData.value = {
      emails: [],
    };
    emit("close");
    showNotification({
      type: "success",
      message: t("organisation.revoke_invitation_success", {
        n: data.enqueuedEntriesCount,
      }),
    });
    if (data.excludedEntries.length > 0) {
      showNotification({
        type: "danger",
        message: t("organisation.revoke_invitation_error", {
          n: data.excludedEntries.length,
          reason: data.excludedEntries[0].reason,
        }),
      });
    }
  }
});

onFetchError(() => {
  showNotification({
    type: "danger",
    message: t("organisation.revoke_invitation_error", {
      n: revokeInvitationData.value.emails.length,
    }),
  });
});
</script>
<template>
  <BulkActionsModal
    class="md:w-1/2"
    :isOpen="props.isOpen"
    :data="revokeInvitationData"
    :steps="steps"
    :title="t('organisation.revoke_invitation')"
    :subtitle="t('organisation.modal_revoke_invitation_description')"
    :confirmButtonProps="{
      label: t('organisation.revoke_invitation'),
      variant: 'danger',
      isLoading: isFetching,
    }"
    @confirm="execute()"
    @close="emit('close')"
  />
</template>
