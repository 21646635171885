<script setup lang="ts" generic="TData">
import { GModal, GBottomSheet } from "@/components";
import { useBreakpoints } from "@/composables";
import { BULK_ACTIONS_MODAL_CONTEXT } from "./context";
import ModalFooter from "./ModalFooter.vue";
import { useStepNavigation } from "./composables/useStepNavigation";
import type { BulkActionsModalProps } from "./types";
import { computed, provide } from "vue";

const props = defineProps<BulkActionsModalProps<TData>>();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "confirm", data: TData): void;
}>();

const stepsCount = props.steps.length;

const {
  navigationState,
  stepsValidity,
  setStepValidity,
  navigateToStep,
  canNavigateToStep,
} = useStepNavigation({
  stepsCount,
  stepsIds: props.steps.map((step) => step.id),
});

const { isSmall } = useBreakpoints();

const currentStep = computed(
  () => props.steps[navigationState.value.currentStepIndex],
);

const confirm = () => {
  if (!navigationState.value.canProceedToNextStep) return;
  emit("confirm", props.data);
};

const reactiveData = computed(() => props.data);

provide(BULK_ACTIONS_MODAL_CONTEXT, {
  data: reactiveData,
  navigationState,
  stepsValidity,
  confirmationButtonProps: props.confirmButtonProps,
  setStepValidity,
  navigateToStep,
  canNavigateToStep,
  confirm,
});
</script>
<template>
  <component
    :is="isSmall ? GBottomSheet : GModal"
    :isOpen="props.isOpen"
    @close="emit('close')"
  >
    <template #title>
      <h2 class="flex gap-1 text-base font-bold md:gap-2 md:text-lg">
        <span>{{ props.title }}</span>
        <span v-if="stepsCount > 1" class="text-grey-60">
          ({{ navigationState.currentStepIndex + 1 }}/{{
            navigationState.stepsCount
          }})
        </span>
      </h2>
    </template>
    <p v-if="props.subtitle">{{ props.subtitle }}</p>
    <component
      :is="currentStep.component"
      :index="navigationState.currentStepIndex"
      :id="currentStep.id"
      v-bind="currentStep.additionalProps"
    />
    <template #actions>
      <ModalFooter />
    </template>
  </component>
</template>
