import { ref, computed, type Ref } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import type { Lesson } from "@/types/lesson";
import type { TableColumn } from "@/patterns";
import { useLocale } from "@/composables";
import {
  useEntitlementsStore,
  useFlagStore,
  useExportStore,
  useWebSessionStore,
} from "@/stores";
import { useCourseBffFetch } from "@/composables/useFetch";
import { trackMixPanelEvent } from "@/services/analytics/mixpanel";

import type {
  ColumnAccessor,
  LessonMetadata,
  LessonsFilters,
  LessonTableDataRow,
} from "./types";
import {
  useDownloadScorm,
  defaultTemplateAttributes,
  removeSpecialCharactersAndAccents,
} from "../components/useDownloadScorm";

import {
  RemoteScormContentType,
  type ContentRemoteScorm,
  type ContentRS,
  type MultiLanguageBundledContentRS,
} from "@/types/export";

export const useLessonsTab = (filters: Ref<LessonsFilters>) => {
  const { t } = useI18n();
  const { locale } = useLocale();
  const exportStore = useExportStore();
  const { shouldExportMultiLanguage } = storeToRefs(exportStore);
  const flagStore = useFlagStore();
  const entitlementsStore = useEntitlementsStore();

  const {
    isFetching,
    data,
    error: lessonError,
  } = useCourseBffFetch("/api/lessons/list").json<Lesson[]>();
  const lessonTableDefinition: TableColumn<ColumnAccessor>[] = [
    {
      id: "imageUrl",
      headerLabel: t("common.thumbnail"),
      accessor: "imageUrl",
      sortable: false,
      smallScreenPosition: "last",
    },
    {
      id: "title",
      headerLabel: t("common.name"),
      accessor: "title",
      smallScreenPosition: "center",
      isGloballyFilterable: true,
    },
    {
      id: "publicationDate",
      headerLabel: t("common.publication_date"),
      accessor: "publicationDate",
    },
    {
      id: "language",
      accessor: "language",
      visibleFrom: false,
    },
    {
      id: "actions",
      headerLabel: t("common.actions"),
    },
  ];

  const shouldDisplayLesson = (lesson: Lesson) => {
    const isLanguageMatch = filters.value.language === lesson.language;
    const lessonAssessmentCheck =
      !lesson.hasAssessment ||
      entitlementsStore.checkEntitlementEnabled("entLessonsAssessments");
    return isLanguageMatch && lessonAssessmentCheck;
  };

  const lessonData = computed(() => {
    return data.value?.filter(shouldDisplayLesson).map((lesson) => ({
      id: lesson.id,
      ghccId: lesson.ghccId,
      title: lesson.title,
      subtitle: lesson.subtitle,
      description: lesson.description,
      imageUrl: lesson.imageUrl,
      language: lesson.language,
      lti11LaunchUrl: `${import.meta.env.VITE_GH_TOOL_DOMAIN}/int-lti/${
        lesson.ghccId
      }`,
      lti13LaunchUrl: `${import.meta.env.VITE_EDUCATION_WEBSITE}/${lesson.language}/lessons/${lesson.id}`,
      lessonUrl: `${import.meta.env.VITE_EDUCATION_WEBSITE}/${lesson.language}/lessons/${lesson.id}`,
      publicationDate: new Date(lesson.publicationDate),
      containerId: lesson.containerId,
    }));
  });

  const selectedRows = ref<LessonTableDataRow[]>([]);

  const exportableData = computed(() => {
    const columns: { id: string; header: string }[] = [];
    const dataSource: LessonMetadata[] = [];

    if (selectedRows.value.length > 0) {
      Object.keys(selectedRows.value[0]).forEach((key) => {
        if (
          key !== "lti13LaunchUrl" ||
          flagStore.shouldForwardLti13ToLmsConnectorFlag
        ) {
          columns.push({
            id: key,
            header: key,
          });
        }
      });

      selectedRows.value.forEach((row) => {
        const rowMeta = lessonData.value?.find(
          (lesson) => lesson.id === row.id,
        );
        if (rowMeta) {
          dataSource.push(rowMeta);
        }
      });
    }
    return { columns, dataSource };
  });

  const {
    downloadScormPackages,
    getLanguageOptions,
    downloadRemoteScormPackages,
  } = useDownloadScorm();

  const getMultiLanguageLessonWrapperName = (lessons: Lesson[]): string => {
    const { title, containerId } = getMultiLanguageDefaultLesson(lessons);

    const containerName = removeSpecialCharactersAndAccents(
      `${containerId}-multi-lang-${title}`.trim(),
    ).toLowerCase();
    return containerName;
  };

  const getMultiLanguageDefaultLesson = (lessons: Lesson[]) => {
    const currentLang = locale.value;
    return (
      lessons.find((lesson) => lesson.language === currentLang) ||
      lessons.find(
        (lesson) => lesson.language.split("-")[0].toUpperCase() === "EN",
      ) ||
      lessons[0]
    );
  };

  const formatScormLessonData = (lessons: Lesson[]): ContentRS[] => {
    return lessons.map((lesson) => ({
      id: lesson.ghccId,
      title: lesson.title,
      name: lesson.title,
      language: getLanguageCodeByLanguageRegion(lesson.language),
    }));
  };

  const formatScormLessonList = ():
    | ContentRS[]
    | MultiLanguageBundledContentRS[] => {
    if (shouldExportMultiLanguage.value) {
      const lessonContainerIds = exportableData.value.dataSource.map(
        (row) => row.containerId,
      );
      const uniqueLessonContainerIds = [...new Set(lessonContainerIds)];
      return uniqueLessonContainerIds.map((containerId) => {
        const lessonsInContainer = data.value.filter(
          (row) => row.containerId === containerId,
        );
        const contentList = formatScormLessonData(lessonsInContainer);
        const item = {
          containerName: getMultiLanguageLessonWrapperName(lessonsInContainer),
          contentListInContainer: contentList,
          languageOptionList: getLanguageOptions(contentList),
        };
        return item;
      });
    } else {
      const lessonList = exportableData.value.dataSource.map((row) =>
        data.value.find((lesson) => lesson.ghccId === row.ghccId),
      );
      return formatScormLessonData(lessonList);
    }
  };

  const formatRemoteScormLessonList = (): ContentRemoteScorm[] => {
    const lessonList = exportableData.value.dataSource.map((row) =>
      data.value.find((lesson) => lesson.ghccId === row.ghccId),
    );
    if (shouldExportMultiLanguage.value) {
      const lessonContainerIds = exportableData.value.dataSource.map(
        (row) => row.containerId,
      );
      const uniqueLessonContainerIds = [...new Set(lessonContainerIds)];
      return uniqueLessonContainerIds.map((containerId) => {
        const lessonsInContainer = data.value.filter(
          (row) => row.containerId === containerId,
        );
        return {
          contentId: containerId,
          contentType: RemoteScormContentType.Lesson,
          isMultipleLanguage: true,
          containerName: getMultiLanguageLessonWrapperName(lessonsInContainer),
          title: getMultiLanguageDefaultLesson(lessonsInContainer).title,
          locale: locale.value,
        };
      });
    } else {
      return lessonList.map((lesson) => ({
        contentId: lesson.ghccId.toString(),
        contentType: RemoteScormContentType.Lesson,
        isMultipleLanguage: false,
        title: lesson.title,
        locale: lesson.language,
      }));
    }
  };

  const { getLanguageCodeByLanguageRegion } = useWebSessionStore();

  const exportToScorm = async () => {
    if (flagStore.scromWithLMSConnectorFlag) {
      const lessonToDownload = formatRemoteScormLessonList();
      await downloadRemoteScormPackages(lessonToDownload);
    } else {
      const lessonToDownload = formatScormLessonList();

      await downloadScormPackages(
        "LESSON",
        lessonToDownload,
        defaultTemplateAttributes,
      );
    }

    trackExportLessons(selectedRows.value);
    selectedRows.value = [];
  };

  const trackExportLessons = (selectedActivities: LessonTableDataRow[]) => {
    trackMixPanelEvent("lessonsExport", {
      count: selectedActivities.length,
      activities: selectedActivities.map((activity) => ({
        id: activity.id,
        name: activity.title,
      })),
      context: "Connect Page",
    });
  };

  return {
    lessonTableDefinition,
    isFetching,
    lessonData,
    lessonError,
    selectedRows,
    exportableData,
    exportToScorm,
    trackExportLessons,
  };
};
