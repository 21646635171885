<script setup lang="ts">
import { computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { BULK_ACTIONS_MODAL_CONTEXT } from "./context";
import { GButton } from "@/components";

const {
  stepsValidity,
  navigationState,
  confirmationButtonProps,
  navigateToStep,
  canNavigateToStep,
  confirm,
} = inject(BULK_ACTIONS_MODAL_CONTEXT);

const { t } = useI18n();
const canConfirm = computed(() =>
  stepsValidity.value.every((step) => step.isValid),
);
</script>
<template>
  <div class="grid grid-cols-1 grid-rows-2 py-4 md:grid-cols-3 md:grid-rows-1">
    <div class="flex gap-2 md:col-start-3 md:place-self-end">
      <GButton
        v-if="navigationState.currentStepIndex > 0"
        class="mx-4 flex-1"
        variant="link"
        @click="navigateToStep(navigationState.currentStepIndex - 1)"
      >
        {{ t("common.previous") }}
      </GButton>
      <GButton
        v-if="navigationState.isLastStep"
        class="flex-1"
        :variant="confirmationButtonProps.variant"
        :disabled="!canConfirm"
        :isLoading="confirmationButtonProps.isLoading"
        @click="confirm"
      >
        {{ confirmationButtonProps.label }}
      </GButton>
      <GButton
        v-else
        class="flex-1 md:flex-none"
        variant="primary"
        :disabled="!navigationState.canProceedToNextStep"
        @click="navigateToStep(navigationState.currentStepIndex + 1)"
      >
        {{ t("common.next") }}
      </GButton>
    </div>
    <div
      v-if="navigationState.stepsCount > 1"
      class="flex gap-2 place-self-center md:col-start-2 md:row-start-1"
    >
      <button
        v-for="step in navigationState.stepsCount"
        :key="step - 1"
        class="size-2 rounded-full focus:ring-2 focus:ring-primary focus:ring-offset-2"
        :class="[
          step - 1 === navigationState.currentStepIndex
            ? 'bg-primary'
            : 'bg-grey-50',
        ]"
        :disabled="!canNavigateToStep(step - 1)"
        :aria-current="step - 1 === navigationState.currentStepIndex"
        :aria-label="`${t('common.step')} ${step}`"
        @click="navigateToStep(step - 1)"
      />
    </div>
  </div>
</template>
