<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { GButton, GModal } from "@/components";
import { userRoles } from "@/stores";
import type { UserRoleValue } from "@gh-dashboard/types";
import useUserActionsStore from "../store";

const emit = defineEmits<{
  (event: "close"): void;
}>();
const { t } = useI18n();

const userActionsStore = useUserActionsStore();
const { activeAction } = storeToRefs(userActionsStore);
const { closeModal } = userActionsStore;

const roles = [
  {
    id: userRoles.admin,
    name: t("organisation.roles.admin"),
    description: t("organisation.roles_description.admin"),
  },
  {
    id: userRoles.reporter,
    name: t("organisation.roles.reporter"),
    description: t("organisation.roles_description.reporter"),
  },
  {
    id: userRoles.groupReporter,
    name: t("organisation.roles.team_reporter"),
    description: t("organisation.roles_description.team_reporter"),
  },
  {
    id: userRoles.connector,
    name: t("organisation.roles.connector"),
    description: t("organisation.roles_description.connector"),
  },
  {
    id: userRoles.marketer,
    name: t("organisation.roles.marketer"),
    description: t("organisation.roles_description.marketer"),
  },
  {
    id: userRoles.accountManager,
    name: t("organisation.roles.account_manager"),
    description: t("organisation.roles_description.account_manager"),
  },
];

const selectedRoles = ref<UserRoleValue[]>([]);
</script>
<template>
  <GModal
    :isOpen="activeAction === 'assignRole'"
    scrollBehaviour="modal"
    :title="t('organisation.assign_role')"
    @close="closeModal()"
    data-testId="assign-role-modal"
  >
    <div class="grid gap-6">
      <!-- eslint-disable vue/no-bare-strings-in-template -->
      <span class="min-w-64 text-xl text-red-500">Coming soon</span>
      <p>{{ t("organisation.modal_assign_role_description") }}</p>
      <span id="role-list-label">{{ t("organisation.role") }}</span>
      <ul
        aria-labelledby="role-list-label"
        class="block h-[40vh] overflow-y-auto lg:w-[40rem]"
      >
        <li
          v-for="{ id, name, description } in roles"
          :key="id"
          class="grid grid-cols-[repeat(2,_auto)] justify-start gap-4 rounded-lg p-4 hover:bg-slate-100"
        >
          <input
            type="checkbox"
            class="checkbox checkbox-primary row-span-2 block"
            :value="id"
            v-model="selectedRoles"
          />
          <span class="block">{{ name }}</span>
          <span class="block">{{ description }}</span>
        </li>
      </ul>
    </div>
    <template #actions>
      <GButton
        @click="() => emit('close')"
        :disabled="true || selectedRoles.length === 0"
        >{{ t("common.save") }}</GButton
      >
    </template>
  </GModal>
</template>
