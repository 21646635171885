<script setup lang="ts">
import { watch, inject, type Ref } from "vue";
import { useI18n } from "vue-i18n";

import BlockConfirmation from "../BlockConfirmation.vue";
import { BULK_ACTIONS_MODAL_CONTEXT } from "../BulkActionsModal/context";
import type { ContextWithData } from "../BulkActionsModal/types";
import type { BulkBlockData } from "./types";

const { data, setStepValidity } = inject(
  BULK_ACTIONS_MODAL_CONTEXT,
) as ContextWithData<Ref<BulkBlockData>>;
const props = defineProps<{
  id: string;
  index: number;
}>();

const { t } = useI18n();
const CONFIRMATION_TEXT = t("organisation.block").toUpperCase();

watch(
  () => data.value.confirmationText,
  (updatedConfirmationText) => {
    setStepValidity({
      id: props.id,
      index: props.index,
      isValid: updatedConfirmationText.toUpperCase() === CONFIRMATION_TEXT,
    });
  },
  { immediate: true },
);
</script>
<template>
  <BlockConfirmation v-model="data.confirmationText" />
</template>
