<script setup lang="ts">
import { ref, useSlots, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import CloseIcon from "@/assets/images/icons/close.svg";
import { onKeyStroke } from "@vueuse/core";
import GButton from "../GButton/GButton.vue";

interface Props {
  isOpen: boolean;
  title?: string;
  closeOnOutsideClick?: boolean;
  hasCloseButton?: boolean;
  closeOnEscape?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  closeOnOutsideClick: false,
  closeOnEscape: false,
  isOpen: true,
  hasCloseButton: true,
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

const { t } = useI18n();
const slots = useSlots();
const dialogRef = ref<HTMLDialogElement | null>(null);

watchEffect(() => {
  if (dialogRef.value) {
    props.isOpen ? dialogRef.value.showModal() : dialogRef.value.close();
  }
});

const handleClickOutside = (event: MouseEvent) => {
  if (!dialogRef.value) return;

  const dialogDimensions = dialogRef.value.getBoundingClientRect();
  if (props.closeOnOutsideClick && event.clientY < dialogDimensions.top) {
    emit("close");
  }
};

onKeyStroke("Escape", (e) => {
  if (!props.closeOnEscape) {
    e.preventDefault();
  } else {
    emit("close");
  }
});
</script>

<template>
  <dialog
    ref="dialogRef"
    class="bottom-sheet bg-white shadow-elevation-l px-4"
    @click="handleClickOutside"
  >
    <div
      v-if="slots.title || title || hasCloseButton"
      class="flex items-center justify-between pb-2 pt-4"
    >
      <slot name="title">
        <h2 v-if="title" class="text-lg font-bold text-black">{{ title }}</h2>
      </slot>
      <GButton
        variant="link"
        class="px-3"
        v-if="hasCloseButton"
        @click="emit('close')"
        :aria-label="t('common.close_modal')"
        data-testid="close-bottom-sheet-button"
      >
        <CloseIcon class="text-grey-60" />
      </GButton>
    </div>

    <slot />

    <div v-if="slots.actions">
      <slot name="actions" />
    </div>
  </dialog>
</template>

<style>
.bottom-sheet {
  --transition-duration: 0.3s;

  transition:
    display var(--transition-duration) allow-discrete,
    overlay var(--transition-duration) allow-discrete,
    transform var(--transition-duration) ease-out allow-discrete;
  transform: translateY(100%);
  inset-inline: 0;
  inset-block-end: 0;
  max-width: 100%;
  max-height: 80%;
  inset-block-start: auto;
}

.bottom-sheet[open] {
  transform: translateY(0);
}

@starting-style {
  .bottom-sheet[open] {
    transform: translateY(100%);
  }
}

.bottom-sheet::backdrop {
  transition: all var(--transition-duration) allow-discrete;
  backdrop-filter: blur(0px);
  background-color: rgba(255 255 255 / 0);
}
.bottom-sheet[open]::backdrop {
  backdrop-filter: blur(2px);
  background-color: rgba(255 255 255 / 0.4);
}
@starting-style {
  .bottom-sheet[open]::backdrop {
    backdrop-filter: blur(0px);
    background-color: rgba(255 255 255 / 0);
  }
}
</style>
