import { computed, ref } from "vue";
import type { StepValidity, StepNavigationState } from "../types";

type UseStepNavigationInput = {
  stepsCount: number;
  stepsIds: string[];
};

export function useStepNavigation({
  stepsCount,
  stepsIds,
}: UseStepNavigationInput) {
  const initialStepsValidity = stepsIds.map((id, index) => ({
    id,
    index,
    isValid: true,
    errors: [],
  }));

  const currentStepIndex = ref(0);
  const stepsValidity = ref<StepValidity[]>(initialStepsValidity);

  const isLastStep = computed(() => currentStepIndex.value === stepsCount - 1);

  const canProceedToNextStep = computed(() => {
    return (
      stepsValidity.value.find((step) => step.index === currentStepIndex.value)
        ?.isValid ?? false
    );
  });

  const canNavigateToStep = (stepIndex: number): boolean => {
    if (stepIndex < 0 || stepIndex >= stepsCount) return false;
    if (stepIndex < currentStepIndex.value) return true;
    if (stepIndex === currentStepIndex.value + 1)
      return canProceedToNextStep.value;
    return false;
  };

  const navigateToStep = (stepIndex: number): boolean => {
    if (!canNavigateToStep(stepIndex)) return false;
    currentStepIndex.value = stepIndex;
    return true;
  };

  const setStepValidity = (stepValidity: StepValidity) => {
    const stepIndex = stepsValidity.value.findIndex(
      (s) => s.index === stepValidity.index,
    );

    if (stepIndex !== -1) {
      stepsValidity.value[stepIndex] = stepValidity;
    }
  };

  const navigationState = computed<StepNavigationState>(() => ({
    currentStepIndex: currentStepIndex.value,
    stepsCount: stepsCount,
    isLastStep: isLastStep.value,
    canProceedToNextStep: canProceedToNextStep.value,
  }));

  return {
    navigationState,
    stepsValidity,
    setStepValidity,
    navigateToStep,
    canNavigateToStep,
  };
}
