<script lang="ts" setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import AnonymiseConfirmationStep from "./AnonymiseConfirmationStep.vue";
import type { BulkAnonymiseData } from "./types";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { useFetch } from "@/composables";
import { useNotificationsStore } from "@/stores";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
  },
  {
    id: "anonymise-confirmation",
    component: AnonymiseConfirmationStep,
  },
];

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const anonymiseData = ref<BulkAnonymiseData>({
  emails: [],
  confirmationText: "",
});
const payload = computed(() => {
  return {
    EmailAddresses: anonymiseData.value.emails,
  };
});

const { t } = useI18n();
const { showNotification } = useNotificationsStore();
const { isFetching, execute, onFetchResponse } = useFetch(
  "/api/AnonymizeUser/ByEmailAddresses",
  { immediate: false },
).post(payload);

onFetchResponse(async (response) => {
  if (response.ok) {
    const data = await response.json();
    anonymiseData.value = {
      emails: [],
      confirmationText: "",
    };
    emit("close");
    showNotification({
      type: "success",
      message: t("organisation.anonymise_success", {
        n: data.enqueuedEntriesCount,
      }),
    });
    if (data.excludedEntries.length > 0) {
      showNotification({
        type: "danger",
        message: t("organisation.anonymise_error", {
          n: data.excludedEntries.length,
          reason: data.excludedEntries[0].reason,
        }),
      });
    }
  }
});
</script>
<template>
  <BulkActionsModal
    class="md:w-1/2"
    :isOpen="props.isOpen"
    :data="anonymiseData"
    :steps="steps"
    :title="t('organisation.anonymise_users')"
    :subtitle="t('organisation.modal_bulk_anonymise_subtitle')"
    :confirmButtonProps="{
      label: t('organisation.anonymise'),
      variant: 'danger',
      isLoading: isFetching,
    }"
    @confirm="execute()"
    @close="emit('close')"
  />
</template>
