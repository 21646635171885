<script lang="ts" setup>
import { ref } from "vue";
import EmailCollectionStep from "../BulkActionsModal/EmailCollectionStep.vue";
import BlockConfirmationStep from "./BlockConfirmationStep.vue";
import type { BulkBlockData } from "./types";
import BulkActionsModal from "../BulkActionsModal/BulkActionsModal.vue";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "@/stores";
import { useFetch, useLocale } from "@/composables";

const steps = [
  {
    id: "email-collection",
    component: EmailCollectionStep,
  },
  {
    id: "block-confirmation",
    component: BlockConfirmationStep,
  },
];

const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();

const blockData = ref<BulkBlockData>({
  emails: [],
  confirmationText: "",
});

const { t } = useI18n();
const { locale } = useLocale();
const { showNotification } = useNotificationsStore();
const { isFetching, execute, onFetchResponse } = useFetch(
  "/api/ActivityQueue",
  { immediate: false },
).delete(() => ({
  command: "remove_user_by_email",
  data: blockData.value.emails.join(","),
  languageCode: locale.value,
  removeMailTxt: "",
}));

onFetchResponse(async (response) => {
  if (response.ok) {
    const data = await response.json();
    blockData.value = {
      emails: [],
      confirmationText: "",
    };
    emit("close");
    showNotification({
      type: "success",
      message: t("organisation.block_success", {
        n: data.enqueuedEntriesCount,
      }),
    });
    if (data.enqueuedEntriesCount.length > 0) {
      showNotification({
        type: "danger",
        message: t("organisation.block_error", {
          n: data.enqueuedEntriesCount,
          reason: data.enqueuedEntriesCount[0].reason,
        }),
      });
    }
  }
});
</script>
<template>
  <BulkActionsModal
    class="w-1/2"
    :isOpen="props.isOpen"
    :data="blockData"
    :steps="steps"
    :title="t('organisation.block_users')"
    :subtitle="t('organisation.modal_bulk_block_subtitle')"
    :confirmButtonProps="{
      label: t('organisation.block'),
      variant: 'danger',
      isLoading: isFetching,
    }"
    @confirm="execute()"
    @close="emit('close')"
  />
</template>
