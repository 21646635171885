<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { GAlert, GButton, GModal } from "@/components";
import useUserActionsStore from "../store";
import { storeToRefs } from "pinia";

const { selectedUsers, blockUsersByIds, closeModal } = useUserActionsStore();
const { activeAction } = storeToRefs(useUserActionsStore());
const { t } = useI18n();
</script>
<template>
  <GModal
    :isOpen="activeAction === 'blockUser'"
    scrollBehaviour="modal"
    :title="t('organisation.block_user(s)', selectedUsers.length || 1)"
    @close="closeModal()"
    data-testId="block-modal"
    class="max-w-[30rem]"
  >
    <div class="grid gap-6">
      <p>{{ t("organisation.modal_block_description") }}</p>
      <GAlert
        :dismissible="false"
        variant="danger"
        :message="t('organisation.modal_block_warning')"
      />
    </div>

    <template #actions>
      <GButton variant="link" @click="closeModal">
        {{ t("common.cancel") }}
      </GButton>
      <GButton variant="primary" @click="blockUsersByIds">
        {{ t("organisation.block") }}
      </GButton>
    </template>
  </GModal>
</template>
