<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { PageLayout, GPageDatePicker, GRestrictedTabView } from "@/components";
import ActiveUsersSection from "./components/ActiveUsersSection.vue";
import CompletedAsmSection from "./components/CompletedAsmSection.vue";
import ActivityPerAsmSection from "./components/ActivityPerAsmSection.vue";
import { useAssessmentsStore } from "@/stores";

const asmStore = useAssessmentsStore();

const { t } = useI18n();

const startDateTimestamp = ref<number>();
const endDateTimestamp = ref<number>();

const convertToUTCTimestamp = (date: Date) => {
  const MILLISECONDS_PER_MINUTE = 60000;
  return date.getTime() - date.getTimezoneOffset() * MILLISECONDS_PER_MINUTE;
};

const handleDatePicked = ([startDate, endDate]: [Date, Date]) => {
  startDateTimestamp.value = convertToUTCTimestamp(startDate);
  endDateTimestamp.value = convertToUTCTimestamp(endDate);
};
</script>
<template>
  <PageLayout :title="t('assessments.title')" :heading="t('assessments.title')">
    <template #primaryAction>
      <GPageDatePicker @update:modelValue="handleDatePicked"></GPageDatePicker>
    </template>
    <GRestrictedTabView v-if="!asmStore.isAssessmentsUser" />
    <div
      class="grid items-stretch gap-4 lg:grid-cols-3"
      v-if="
        asmStore.isAssessmentsUser && startDateTimestamp && endDateTimestamp
      "
    >
      <ActiveUsersSection
        :startDate="startDateTimestamp"
        :endDate="endDateTimestamp"
      />
      <CompletedAsmSection
        :startDate="startDateTimestamp"
        :endDate="endDateTimestamp"
      />
      <ActivityPerAsmSection
        :startDate="startDateTimestamp"
        :endDate="endDateTimestamp"
      />
    </div>
  </PageLayout>
</template>
