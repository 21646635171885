<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { endOfDay, formatISO, parseISO, startOfDay } from "date-fns";

import {
  BarChart,
  GButton,
  GPageDatePicker,
  InsightCard,
  PageLayout,
} from "@/components";
import { useUserReportDetails } from "./useUserReportDetails";
import CourseProgressTable from "./CourseProgressTable.vue";
import StatsCard from "../components/StatsCard.vue";

import ClockIcon from "@/assets/images/icons/clock.svg";
import FileCertificateIcon from "@/assets/images/icons/file-certificate.svg";
import ArrowDownToLineIcon from "@/assets/images/icons/arrow-down-to-line.svg";

const iconNameMap: Record<string, string> = {
  clock: ClockIcon,
  fileCertificate: FileCertificateIcon,
};

const router = useRouter();
const { t } = useI18n();

const isExportModalOpen = ref(false);

const userName = computed(() => router.currentRoute.value.query.name as string);
const dateFrom = computed(
  () => router.currentRoute.value.query.dateFrom as string,
);
const dateTo = computed(() => router.currentRoute.value.query.dateTo as string);

const { userDetails, isFetching, statsData, formattedActivityVisitsData } =
  useUserReportDetails({
    dateFrom,
    dateTo,
  });

const handleChangeDate = async (dates: [Date, Date]) => {
  await router.replace({
    query: {
      ...router.currentRoute.value.query,
      dateFrom: formatISO(dates[0], { representation: "date" }),
      dateTo: formatISO(dates[1], { representation: "date" }),
    },
  });
};
</script>

<template>
  <PageLayout
    :title="t('userReportDetails.title')"
    :heading="userName"
    :backButton="{
      label: t('reports.btn_back_to_reports'),
      url: `/admin-dashboard/reports/students`,
    }"
  >
    <div class="grid grid-cols-3 gap-x-4 gap-y-5">
      <div
        class="col-span-3 flex w-full flex-col justify-between gap-y-4 lg:flex-row"
      >
        <GPageDatePicker
          :modelValue="[
            startOfDay(parseISO(dateFrom)),
            endOfDay(parseISO(dateTo)),
          ]"
          @update:modelValue="handleChangeDate"
        />

        <div class="flex items-end gap-3">
          <GButton
            variant="secondary"
            size="small"
            @click="isExportModalOpen = true"
            :disabled="isFetching || !userDetails?.CourseDetails?.length"
          >
            {{ t("courseDetails.btn_action_secondary") }}
            <template #suffixIcon>
              <ArrowDownToLineIcon />
            </template>
          </GButton>
        </div>
      </div>
      <StatsCard
        v-for="stats in statsData"
        :key="`${stats.data[0].label}-${stats.data[0].value}`"
        class="col-span-3 lg:col-span-1"
        :stats="stats.data"
        :isLoading="isFetching"
      >
        <template #icon>
          <component :is="iconNameMap[stats.icon]" class="fill-primary" />
        </template>
      </StatsCard>

      <InsightCard
        :isLoading="isFetching"
        :title="t('reports.tbl_col_lesson_visits')"
        :subtitle="t('reports.activity_list_subtitle')"
        class="col-span-3"
      >
        <BarChart
          :xValues="formattedActivityVisitsData.labels"
          :yValues="
            formattedActivityVisitsData.data.length > 0
              ? formattedActivityVisitsData.data
              : [0]
          "
        />
      </InsightCard>
      <div class="col-span-3 mt-8">
        <CourseProgressTable
          :data="userDetails?.CourseDetails"
          :isLoading="isFetching"
          :isExportModalOpen="isExportModalOpen"
          @updateIsExportModalOpen="isExportModalOpen = $event"
        />
      </div>
    </div>
  </PageLayout>
</template>
