<script setup lang="ts">
import { computed, ref } from "vue";
import { isSameDay, isDate, isValid, format } from "date-fns";
import Datepicker from "@vuepic/vue-datepicker";
import { TextInput, GButton } from "@/components";
import { PresetId, useDatePicker } from "./useDatePicker";

import CalendarIcon from "@/assets/images/icons/calendar.svg";
import PlusIcon from "@/assets/images/icons/plus.svg";
import { isDateTuple } from "@/utils/typeGuards";

const { defaultPresetRanges } = useDatePicker();

const props = withDefaults(
  defineProps<{
    label: string;
    modelValue: Date | [Date, Date] | null;
    transitions?: boolean;
    enableTimePicker?: boolean;
    disablePresetRanges?: boolean;
    withButtonPresets?: boolean;
    presetRanges?: Array<PresetId>;
    range?: boolean;
    noToday?: boolean;
    keepActionRow?: boolean;
    placeholder?: string;
    autoApply?: boolean;
    withCustomRangeLabel?: boolean;
  }>(),
  {
    range: true,
    enableTimePicker: false,
    withButtonPresets: false,
    autoApply: true,
    transitions: false,
    noToday: false,
    keepActionRow: false,
    withCustomRangeLabel: false,
  },
);

const emit = defineEmits<{
  (event: "update:modelValue", value: Date | [Date, Date] | null): void;
}>();

const datepicker = ref();
const datepickerInput = ref();

const presets = computed(() => {
  if (props.disablePresetRanges) return undefined;
  if (props.presetRanges)
    return props.presetRanges
      .map((rangeId) =>
        defaultPresetRanges.find((range) => range.id === rangeId),
      )
      .filter(Boolean) as typeof defaultPresetRanges;
  else return defaultPresetRanges;
});

const buttonPresets = computed(() => {
  return props.withButtonPresets ? presets.value : undefined;
});

const isCustomRange = computed(() => {
  return (
    presets.value &&
    !presets.value?.some(
      (preset) =>
        JSON.stringify(preset?.value) === JSON.stringify(props.modelValue),
    )
  );
});

const formattedDateLabel = computed(() => {
  if (isDate(props.modelValue) && isValid(props.modelValue)) {
    return format(props.modelValue as Date, "dd/MM/yyyy");
  }
  if (isDateTuple(props.modelValue)) {
    if (isSameDay(props.modelValue[0], props.modelValue[1])) {
      return format(props.modelValue[0], "PP");
    }

    return `${format(props.modelValue[0], "PP")} - ${format(
      props.modelValue[1],
      "PP",
    )}`;
  }
  return "";
});
</script>

<template>
  <div>
    <span
      v-if="withCustomRangeLabel"
      class="mb-1 ml-auto mr-1 block text-end text-sm text-primary opacity-70"
      >{{ formattedDateLabel }}</span
    >

    <div class="flex flex-wrap gap-2 md:flex-nowrap md:gap-0">
      <GButton
        v-for="presetRange in buttonPresets"
        :key="presetRange.label"
        class="-mr-[1px] rounded-none border border-grey-20 first-of-type:rounded-l-lg"
        :class="
          JSON.stringify(presetRange.value) === JSON.stringify(modelValue)
            ? '!bg-primary !text-white'
            : '!bg-white hover:!bg-blue-10'
        "
        variant="secondary"
        @click="emit('update:modelValue', presetRange.value as [Date, Date])"
        >{{ presetRange.label }}</GButton
      >
      <Datepicker
        class="w-full"
        ref="datepicker"
        data-testid="date-picker"
        :range="range"
        :keepActionRow="props.keepActionRow"
        :noToday="props.noToday"
        :maxDate="new Date()"
        :transitions="props.transitions"
        :autoApply="props.autoApply"
        :presetDates="presets"
        :value="props.range"
        :enableTimePicker="props.enableTimePicker"
        :modelValue="props.modelValue"
        @update:modelValue="emit('update:modelValue', $event)"
      >
        <template #trigger>
          <GButton
            v-if="withButtonPresets"
            variant="secondary"
            @click.stop="datepicker.openMenu()"
            class="rounded-l-none border border-grey-20"
            :class="
              isCustomRange
                ? '!bg-primary !text-white'
                : '!bg-white hover:!bg-blue-10'
            "
          >
            <template #prefixIcon>
              <PlusIcon class="fill-primary" />
            </template>
            {{ $t("common.custom") }}
          </GButton>

          <TextInput
            v-else
            class="w-full"
            data-testid="datepicker-input-trigger"
            name="date"
            ref="datepickerInput"
            :placeholder="props.placeholder"
            fullWidth
            readonly
            :modelValue="formattedDateLabel"
            :label="props.label"
            @keydown.enter="datepicker.openMenu()"
            @keydown.space="datepicker.openMenu()"
            @keydown.escape="datepicker.closeMenu()"
          >
            <template #prefixIcon>
              <CalendarIcon />
            </template>
          </TextInput>
        </template>
      </Datepicker>
    </div>
  </div>
</template>
<style lang="scss">
$dp__font_family: "CircularXXTT", system-ui, sans-serif;
$dp__menu_min_width: 390px;

@import "@vuepic/vue-datepicker/dist/main.css";

.dp__theme_light {
  --dp-background-color: #ffffff;
  --dp-text-color: #212121;
  --dp-hover-color: #eee8fd; /* blue-10 */
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #5a1eec; /* primary */
  --dp-primary-text-color: #ffffff;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #e1e1e1; /* grey-10 */
  --dp-menu-border-color: #e1e1e1; /* grey-10 */
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #eee8fd; /* blue-10 */
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-highlight-color: #eee8fd; /* blue-10 */
}

.dp__instance_calendar,
.dp__preset_ranges {
  @apply p-2;
}
</style>
