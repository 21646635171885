<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";

import { GToggleBar, PageLayout } from "@/components";
import { BulkBlockModal } from "./Organisation/Users/components/BulkBlockModal";
import { BulkAnonymiseModal } from "./Organisation/Users/components/BulkAnonymiseModal";
import { BulkAddToTeamsModal } from "./Organisation/Users/components/BulkAddToTeamsModal";
import { BulkRevokeInvitationModal } from "./Organisation/Users/components/BulkRevokeInvitationModal";

const { t } = useI18n();

const isModalOpen = ref(false);
const isModalOpen2 = ref(false);
const isModalOpen3 = ref(false);
const isModalOpen4 = ref(false);
</script>
<template>
  <GToggleBar :label="t('common.toggle_bar_label')" opt="in" />
  <PageLayout :title="t('overview.title')">
    <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
    <button @click="isModalOpen = true">Open Anon Modal</button>
    <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
    <button @click="isModalOpen2 = true">Open Add to Teams Modal</button>
    <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
    <button @click="isModalOpen3 = true">Open Revoke Invitation Modal</button>
    <!-- eslint-disable-next-line vue/no-bare-strings-in-template -->
    <button @click="isModalOpen4 = true">Open Block Modal</button>

    <BulkAnonymiseModal :isOpen="isModalOpen" @close="isModalOpen = false" />
    <BulkAddToTeamsModal :isOpen="isModalOpen2" @close="isModalOpen2 = false" />
    <BulkRevokeInvitationModal
      :isOpen="isModalOpen3"
      @close="isModalOpen3 = false"
    />
    <BulkBlockModal :isOpen="isModalOpen4" @close="isModalOpen4 = false" />
  </PageLayout>
</template>
