<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { startOfDay, endOfToday, sub } from "date-fns";

import { GDatePicker } from "@/components";
import { PresetId } from "./useDatePicker";
import { onMounted } from "vue";

const { t } = useI18n();

const modelValue = defineModel<[Date, Date]>();

onMounted(() => {
  if (!modelValue.value) {
    modelValue.value = [startOfDay(sub(new Date(), { days: 6 })), endOfToday()];
  }
});
</script>
<template>
  <GDatePicker
    :label="t('common.choose_date_interval')"
    v-model="modelValue"
    :presetRanges="[
      PresetId.LAST_12_MONTHS,
      PresetId.LAST_30_DAYS,
      PresetId.LAST_7_DAYS,
    ]"
    withButtonPresets
    withCustomRangeLabel
    :noToday="false"
  ></GDatePicker>
</template>
