<script setup lang="ts">
import { computed, onMounted, watch, type Ref } from "vue";
import {
  useEditor,
  EditorContent,
  Node,
  VueNodeViewRenderer,
  nodePasteRule,
  nodeInputRule,
  textPasteRule,
  textInputRule,
  Editor,
} from "@tiptap/vue-3";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
import { useI18n } from "vue-i18n";
import type { ZodSchema } from "zod";

import { useEmailValidation } from "@/composables/useEmailValidation";
import EmailTagComponent from "./EmailTag.vue";

type Props = {
  modelValue: string[];
  placeholder?: string;
  disabled?: boolean;
  additionalValidationSchemas?: ZodSchema[];
  autoFocus?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  additionalValidationSchemas: () => [],
});

const emit = defineEmits<{
  (e: "update:modelValue", value: string[]): void;
  (
    e: "error",
    errors: Array<{
      type: string;
      path: number[];
    }>,
  ): void;
}>();

const { t } = useI18n();

const { uniqueEmailListSchema, composeSchemas, getEmailErrorType } =
  useEmailValidation();

const validateTags = (tags: string[]) => {
  const validationSchema = composeSchemas([
    uniqueEmailListSchema,
    ...props.additionalValidationSchemas,
  ]);

  const validationResult = validationSchema.safeParse(tags);
  return (
    validationResult.error?.issues.map((issue) => ({
      type: getEmailErrorType(issue),
      path: issue.path.map((path) => Number(path)),
      email: tags[issue.path[0]],
    })) ?? []
  );
};

const getEmailAttrs = (email: string) => {
  const errors = validateTags([...props.modelValue, email]);
  return {
    text: email,
    hasError: errors.some((error) => error.email === email),
  };
};

const EmailTag = Node.create({
  name: "emailTag",
  group: "inline",
  inline: true,
  atom: true,

  addNodeView() {
    return VueNodeViewRenderer(EmailTagComponent);
  },

  parseHTML() {
    return [{ tag: "g-tag" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["g-tag", HTMLAttributes];
  },

  addAttributes() {
    return {
      text: { default: "" },
      hasError: { default: false },
    };
  },

  addInputRules() {
    const leafMarker = "%leaf%";
    return [
      nodeInputRule({
        // Matches any characters except whitespace/comma/semicolon ([^\s,;]+), followed by a delimiter (space/comma/semicolon) ([\s,;]) at the end of string ($)
        find: new RegExp(`([^\\s,;]+)(?<!${leafMarker})([\\s,;])$`),
        type: this.type,
        getAttributes: (match) => {
          return getEmailAttrs(match[1]);
        },
      }),
      textInputRule({
        // Normalize all allowed delimiters to spaces
        find: /[,;\s]+$/,
        replace: " ",
      }),
    ];
  },

  addPasteRules() {
    return [
      textPasteRule({
        // Normalize all allowed delimiters to spaces
        find: /[,;\s]+/g,
        replace: " ",
      }),
      nodePasteRule({
        // Match one or more characters that are not whitespace
        // which will match the entire email address as a single node
        find: /[^\s]+/g,
        type: this.type,
        getAttributes(match) {
          return getEmailAttrs(match[0]);
        },
      }),
    ];
  },
});

const editor = useEditor({
  extensions: [
    Document,
    Paragraph,
    Text,
    Placeholder.configure({ placeholder: props.placeholder }),
    EmailTag,
  ],
  editable: !props.disabled,
  editorProps: {
    attributes: {
      class: "prose prose-sm focus:outline-none",
      role: "textbox",
    },
  },
  autofocus: props.autoFocus,
}) as Ref<Editor>;

const tags = computed(() => {
  const emails = editor.value?.state.doc
    .toJSON()
    .content[0].content?.filter((node) => node.type === EmailTag.name);
  return (emails?.map((email) => email.attrs.text) as string[]) ?? [];
});

const errors = computed(() => {
  return validateTags(tags.value);
});

const errorMessages = computed(() => {
  const errorsSet = new Set(errors.value.map((error) => error.type));
  const errorMessages = {
    duplicate: t("validationMessages.duplicate_emails"),
    invalid: t("validationMessages.invalid_emails"),
    ownEmail: t("validationMessages.own_email"),
  };

  return Array.from(errorsSet).map((errorType) => errorMessages[errorType]);
});

onMounted(() => {
  if (props.modelValue.length > 0 && editor.value) {
    const errors = validateTags(props.modelValue);
    editor.value.commands.setContent({
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: props.modelValue.flatMap((email) => [
            {
              type: EmailTag.name,
              attrs: {
                text: email,
                hasError: errors.some((error) => error.email === email),
              },
            },
            {
              type: "text",
              text: " ",
            },
          ]),
        },
      ],
    });
  }
});

watch(tags, (updatedTags, oldTags) => {
  if (
    oldTags.length === updatedTags.length &&
    updatedTags.every((tag, index) => tag === oldTags[index])
  ) {
    return;
  }

  emit("update:modelValue", updatedTags);

  if (errors.value.length > 0) {
    emit("error", errors.value);
  } else {
    emit("error", []);
  }
});
</script>

<template>
  <div
    class="flex flex-col rounded-lg border bg-white p-2 h-48 overflow-y-auto"
    :class="[
      disabled ? 'cursor-not-allowed bg-grey-5' : '',
      errors.length > 0
        ? 'border-error'
        : '[:not(:disabled)]:focus-within:border-primary border-grey-20',
    ]"
  >
    <EditorContent :editor="editor" />
  </div>
  <div class="text-sm text-error" v-if="errors.length > 0">
    <p
      v-for="error in errorMessages"
      :key="error"
      data-testid="error-message"
      role="alert"
    >
      {{ error }}
    </p>
  </div>
</template>
<style>
.tiptap {
  flex: 1;
  overflow-y: auto;
}

div:has(> .tiptap) {
  flex: 1;
  display: flex;
}

.tiptap p.is-editor-empty:first-child::before {
  color: var(--grey-60);
  font-size: 14px;
  pointer-events: none;
  float: left;
  height: 0;
  content: attr(data-placeholder);
}
</style>
